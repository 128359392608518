<template>
  <div>
    <b-card>
      <div class="row mb-3">
        <div class="col-12 d-flex">
          <span class="h2 mr-auto text-secondary"> Nouvel acteur</span>

          <div>
            <modal-actions
              @close="
                $router.go(
                  $store.getters['routerHistory/fromCreation'] ? -2 : -1
                )
              "
            />
          </div>
        </div>
      </div>
      <div class="shdow">
        <div
          class="d-flex flex-wrap justify-content-between align-items-center"
        >
          <span class="h3 text-secondary">Informations générales</span>
          <div>
            <b-button
              @click="addUser"
              type="submit"
              pill
              :disabled="submitingForm"
            >
              <b-spinner
                small
                v-if="submitingForm"
                class="text-white"
                label="Loading..."
              ></b-spinner>
              Confirmer</b-button
            >
          </div>
        </div>
        <hr class="mt-1 mb-3 bg-secondary" />
        <div class="row">
          <!-- First Col -->
          <div class="col-12 col-md-4 pr-0">
            <b-form-group
              label-cols="4"
              label="Nom :"
              label-class="font-weight-bold"
            >
              <b-form-input
                placeholder="Nom"
                :class="{
                  'is-invalid':
                    $v.newActeur.Nom.$error && $v.newActeur.Nom.$dirty,
                }"
                v-model="newActeur.Nom"
                type="text"
              >
              </b-form-input>
              <span
                style="font-size: x-small"
                class="text-danger font-weight-normal"
                v-if="$v.newActeur.Nom.$dirty"
              >
                {{ !$v.newActeur.Nom.required ? "Champ obligatoire" : "" }}
              </span>
            </b-form-group>
            <b-form-group
              label-cols="4"
              label="Email :"
              label-class="font-weight-bold"
            >
              <b-form-input
                placeholder="Email"
                :class="{
                  'is-invalid':
                    $v.newActeur.Email.$error && $v.newActeur.Email.$dirty,
                }"
                type="email"
                v-model="newActeur.Email"
              ></b-form-input>
              <span
                style="font-size: x-small"
                class="text-danger font-weight-normal"
                v-if="$v.newActeur.Email.$dirty"
              >
                {{ !$v.newActeur.Email.required ? "Champ obligatoire" : "" }}
              </span>
            </b-form-group>

            <b-form-group
              label-cols="4"
              label="Entité :"
              label-class="font-weight-bold"
            >
              <v-select
                label="text"
                :reduce="({ value }) => value"
                v-model="selectedEntite"
                :options="entitesList"
                placeholder="Entité"
                style="padding-right: 0 !important"
                :class="{
                  'is-invalid form-control p-0':
                    $v.selectedEntite.$error && $v.selectedEntite.$dirty,
                }"
              >
                <template #no-options> Liste vide </template>
              </v-select>
              <span
                style="font-size: x-small"
                class="text-danger font-weight-normal"
                v-if="$v.selectedEntite.$dirty"
              >
                {{ !$v.selectedEntite.required ? "Champ obligatoire" : "" }}
              </span>
            </b-form-group>

            <b-form-group
              label-cols="4"
              label="Organisation :"
              label-class="font-weight-bold"
            >
              <v-select
                label="text"
                :reduce="(org) => org.value"
                v-model="newActeur.Organisation"
                :options="organisation"
                placeholder="Organisation"
                style="padding-right: 0 !important"
                :class="{
                  'is-invalid form-control p-0':
                    $v.newActeur.Organisation.$error &&
                    $v.newActeur.Organisation.$dirty,
                }"
              >
                <template #no-options> Liste vide </template>
              </v-select>
              <span
                style="font-size: x-small"
                class="text-danger font-weight-normal"
                v-if="$v.newActeur.Organisation.$dirty"
              >
                {{
                  !$v.newActeur.Organisation.required ? "Champ obligatoire" : ""
                }}
              </span>
            </b-form-group>

            <b-form-group
              label-cols="4"
              label="Fonction :"
              label-class="font-weight-bold"
            >
              <b-form-input
                placeholder="Fonction"
                v-model="newActeur.Fonction"
                :options="hierachie"
                style="padding-right: 0 !important"
                :class="{
                  'is-invalid':
                    $v.newActeur.Fonction.$error &&
                    $v.newActeur.Fonction.$dirty,
                }"
              >
              </b-form-input>
              <span
                style="font-size: x-small"
                class="text-danger font-weight-normal"
                v-if="$v.newActeur.Fonction.$dirty"
              >
                {{ !$v.newActeur.Fonction.required ? "Champ obligatoire" : "" }}
              </span>
            </b-form-group>
          </div>

          <!-- Second Col -->
          <div class="col-12 col-md-4 pr-0">
            <b-form-group
              label-cols="4"
              label="Prénom :"
              label-class="font-weight-bold"
            >
              <b-form-input
                placeholder="Prénom"
                type="text"
                :class="{
                  'is-invalid':
                    $v.newActeur.Prenom.$error && $v.newActeur.Prenom.$dirty,
                }"
                v-model="newActeur.Prenom"
              ></b-form-input>
              <span
                style="font-size: x-small"
                class="text-danger font-weight-normal"
                v-if="$v.newActeur.Prenom.$dirty"
              >
                {{ !$v.newActeur.Prenom.required ? "Champ obligatoire" : "" }}
              </span>
            </b-form-group>
            <b-form-group
              label-cols="4"
              label="Type :"
              label-class="font-weight-bold"
            >
              <v-select
                placeholder="Type"
                v-model="newActeur.type"
                :options="['Externe', 'Interne']"
                style="padding-right: 0 !important"
                :class="{
                  'is-invalid form-control p-0':
                    $v.newActeur.type.$error && $v.newActeur.type.$dirty,
                }"
              >
                <template #no-options> Liste vide </template>
              </v-select>
              <span
                style="font-size: x-small"
                class="text-danger font-weight-normal"
                v-if="$v.newActeur.type.$dirty"
              >
                {{ !$v.newActeur.type.required ? "Champ obligatoire" : "" }}
              </span>
            </b-form-group>
            <b-form-group
              label-cols="4"
              label="Niveau hiérarchique :"
              label-class="font-weight-bold"
            >
              <v-select
                v-model="newActeur.Niveau"
                :options="hierachie"
                placeholder="Niveau hiérarchique"
                style="padding-right: 0 !important"
                :class="{
                  'is-invalid form-control p-0':
                    $v.newActeur.Niveau.$error && $v.newActeur.Niveau.$dirty,
                }"
              >
                <template #no-options> Liste vide </template>
              </v-select>
              <span
                style="font-size: x-small"
                class="text-danger font-weight-normal"
                v-if="$v.newActeur.Niveau.$dirty"
              >
                {{ !$v.newActeur.Niveau.required ? "Champ obligatoire" : "" }}
              </span>
            </b-form-group>
            <!-- <b-form-group
              label-cols="12"
              label="Statut de compte :"
              label-class="font-weight-bold"
            >
              <b-form-checkbox
                v-model="newActeur.Statut"
                switch
                size="lg"
                variant="success"
              ></b-form-checkbox>
            </b-form-group> -->
          </div>

          <!-- Third Col -->
          <div class="col-12 col-md-4">
            <b-form-group
              label-cols="12"
              label="Photo :"
              label-class="font-weight-bold h-25"
            >
              <drop-zone
                :image="''"
                :options="dropzoneOptions"
                @change="change"
              ></drop-zone>
            </b-form-group>
          </div>
        </div>
      </div>
      <br />
      <hr />
      <div class="row mt-4">
        <div
          class="
            col-12
            d-flex
            flex-wrap
            justify-content-between
            align-items-center
          "
        >
          <span class="h4 text-secondary mr-auto my-auto"
            >Liste des équipes
          </span>
          <b-button
            style="
              background-color: #007bffff !important;
              border-color: #007bffff !important;
            "
            disabled
            variant="secondary"
            @click="addNewTeamInputs"
            ><i class="mdi mdi-plus"></i> Affecter équipe</b-button
          >
          <div class="col-12 p-0">
            <hr class="mt-1 mb-3 bg-secondary" />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <b-table
            responsive
            :items="[]"
            :fields="affectedTeams.tableFields"
            show-empty
            bordered
            striped
          >
            <template #empty>
              <div class="text-center text-gray">Table vide</div>
            </template>
          </b-table>
        </div>
      </div>
    </b-card>
  </div>
</template>

<script>
import ModalActions from "@/components/custom/ModalActions.vue";
import DropZone from "@/components/custom/DropZone.vue";
import { required, email } from "vuelidate/lib/validators";
import { mapGetters } from "vuex";
import Swal from "sweetalert2";
import { ORGANISATION_URL } from "@/helpers/constURL";

export default {
  components: {
    ModalActions,
    DropZone,
  },
  data: () => ({
    image: null,
    dropzoneOptions: {
      dictDefaultMessage:
        "Déposez une image ici ou cliquez pour sélectionner une image.",
      url: "https://httpbin.org/post",
      thumbnailWidth: 280,
      maxFilesize: 0.5,
      maxFiles: 1,
      addRemoveLinks: true,
      parallelUploads: 1,
    },
    affectedTeams: {
      tableItems: [
        {
          team_label: "Lorem Ipsum",
          responsable: "Leslie Bechtelar",
          organisation_attachment: "Cadrage",
          collaborators_number: "4",
          actions: ["edit"],
        },
        {
          team_label: "Guillermo Nienow",
          responsable: "Ms. Melissa Fay",
          organisation_attachment: "Cadrage",
          collaborators_number: "6",
          actions: ["edit"],
        },
      ],
      tableFields: [
        { key: "team_label", label: "Libellé équipe", sortable: true },
        { key: "responsable", label: "Responsable", sortable: true },
        {
          key: "organisation_attachment",
          label: "Organisation de rattachement",
          sortable: true,
        },
        {
          key: "collaborators_number",
          label: "Nombre de collaborateurs",
          sortable: true,
        },
        {
          key: "actions",
          label: "Actions",
          thStyle: { width: "150px !important", minWidth: "150px !important" },
          sortable: false,
        },
      ],
    },
    newActeur: {
      Nom: "",
      Prenom: "",
      Email: "",
      Organisation: null,
      Fonction: null,
      Niveau: "",
      Statut: false,
      type: "",
    },
    submitingForm: false,
    selectedEntite: "",
  }),
  validations: {
    selectedEntite: {
      required,
    },
    newActeur: {
      Nom: {
        required,
      },
      Prenom: {
        required,
      },
      Email: {
        required,
        email,
      },
      type: {
        required,
      },
      Fonction: {
        required,
      },
      Niveau: {
        required,
      },
      Organisation: {
        required,
      },
    },
  },

  methods: {
    change(files) {
      this.image = files;
    },
    addUser() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.$store
          .dispatch(
            "organisation/fetchOrganisation",
            this.newActeur.Organisation.value
          )
          .then(() => {
            var item = {
              email: this.newActeur.Email,
              firstname: this.newActeur.Nom,
              lastname: this.newActeur.Prenom,
              type: this.newActeur.type,
              password: "demo",
              isActif: this.newActeur.Statut,
              niveau: this.newActeur.Niveau,
              organisationRatachement: `/api/${ORGANISATION_URL}/${this.newActeur.Organisation}`,
              function: this.newActeur.Fonction ? this.newActeur.Fonction : "",
              hiearchie: this.newActeur.Niveau ? this.newActeur.Niveau : "",
              isVerified: true,
            };
            this.$store.dispatch("users/createUsers", item).then(() => {
              this.submitingForm = false;
              Swal.fire({
                title: "L'acteur est bien créé !",
                type: "success",
                confirmButtonText: "OK",
              }).then((result) => {
                if (result.value) {
                  this.$router.push({
                    name: "show-actor",
                    params: {
                      slug: this.USER.slug || "abcde",
                      id: this.USER.id,
                      fromCreation: true,
                    },
                  });
                }
              });
            });
          });
      }
    },
    addNewTeamInputs() {
      this.affectedTeams.tableItems.push({ editMode: true });
    },
    removeTeamInputs(itemIndex) {
      this.affectedTeams.tableItems = this.affectedTeams.tableItems.filter(
        (team, index) => index !== itemIndex
      );
    },
  },
  watch: {
    selectedEntite() {
      this.newActeur.Organisation = null;
    },
    USER() {
      this.submitingForm = false;
      let i = 1;
      if (this.image && i === 1)
        fetch(this.image.dataURL)
          .then((res) => res.blob())
          .then((blob) => {
            const fd = new FormData();
            const file = new File([blob], this.image.name);
            fd.append("avatar", file);
            this.$store
              .dispatch("users/createImage", {
                id: this.USER.id,
                file: fd,
              })
              .then(() => {
                i++;
              });
          });
    },
  },

  created() {
    this.$store.dispatch("organisation/fetchAllOrganisations");
    this.$store.dispatch("users/fetchierachie");
    this.$store.dispatch("users/fetchfunction");
    this.$store.dispatch("entity/fetchAllEntities");
  },
  computed: {
    ...mapGetters("organisation", ["ORGANISATION"]),
    ...mapGetters("users", ["USER"]),
    ...mapGetters("organisation", ["ORGANISATIONS"]),
    ...mapGetters("users", ["function"]),
    ...mapGetters("users", ["HIERACHIE"]),
    ...mapGetters("entity", ["ENTITIES"]),

    fonction() {
      return this.function;
    },
    hierachie() {
      return this.HIERACHIE;
    },
    entitesList() {
      return this.ENTITIES.filter(
        ({ organisations }) => organisations && organisations.length
      ).map((entity) => ({
        value: entity["@id"],
        text: entity.libelle,
      }));
    },
    organisation() {
      return this.selectedEntite
        ? this.ORGANISATIONS.filter(
            (organisation) =>
              organisation.entite &&
              this.selectedEntite === organisation.entite["@id"]
          ).map((org) => ({
            value: `${org.id}`,
            text: org.libelle,
          }))
        : [];
    },
  },
};
</script>

<style></style>
