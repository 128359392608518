var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('b-card',[_c('div',{staticClass:"row mb-3"},[_c('div',{staticClass:"col-12 d-flex"},[_c('span',{staticClass:"h2 mr-auto text-secondary"},[_vm._v(" Nouvel acteur")]),_c('div',[_c('modal-actions',{on:{"close":function($event){return _vm.$router.go(
                _vm.$store.getters['routerHistory/fromCreation'] ? -2 : -1
              )}}})],1)])]),_c('div',{staticClass:"shdow"},[_c('div',{staticClass:"d-flex flex-wrap justify-content-between align-items-center"},[_c('span',{staticClass:"h3 text-secondary"},[_vm._v("Informations générales")]),_c('div',[_c('b-button',{attrs:{"type":"submit","pill":"","disabled":_vm.submitingForm},on:{"click":_vm.addUser}},[(_vm.submitingForm)?_c('b-spinner',{staticClass:"text-white",attrs:{"small":"","label":"Loading..."}}):_vm._e(),_vm._v(" Confirmer")],1)],1)]),_c('hr',{staticClass:"mt-1 mb-3 bg-secondary"}),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-md-4 pr-0"},[_c('b-form-group',{attrs:{"label-cols":"4","label":"Nom :","label-class":"font-weight-bold"}},[_c('b-form-input',{class:{
                'is-invalid':
                  _vm.$v.newActeur.Nom.$error && _vm.$v.newActeur.Nom.$dirty,
              },attrs:{"placeholder":"Nom","type":"text"},model:{value:(_vm.newActeur.Nom),callback:function ($$v) {_vm.$set(_vm.newActeur, "Nom", $$v)},expression:"newActeur.Nom"}}),(_vm.$v.newActeur.Nom.$dirty)?_c('span',{staticClass:"text-danger font-weight-normal",staticStyle:{"font-size":"x-small"}},[_vm._v(" "+_vm._s(!_vm.$v.newActeur.Nom.required ? "Champ obligatoire" : "")+" ")]):_vm._e()],1),_c('b-form-group',{attrs:{"label-cols":"4","label":"Email :","label-class":"font-weight-bold"}},[_c('b-form-input',{class:{
                'is-invalid':
                  _vm.$v.newActeur.Email.$error && _vm.$v.newActeur.Email.$dirty,
              },attrs:{"placeholder":"Email","type":"email"},model:{value:(_vm.newActeur.Email),callback:function ($$v) {_vm.$set(_vm.newActeur, "Email", $$v)},expression:"newActeur.Email"}}),(_vm.$v.newActeur.Email.$dirty)?_c('span',{staticClass:"text-danger font-weight-normal",staticStyle:{"font-size":"x-small"}},[_vm._v(" "+_vm._s(!_vm.$v.newActeur.Email.required ? "Champ obligatoire" : "")+" ")]):_vm._e()],1),_c('b-form-group',{attrs:{"label-cols":"4","label":"Entité :","label-class":"font-weight-bold"}},[_c('v-select',{class:{
                'is-invalid form-control p-0':
                  _vm.$v.selectedEntite.$error && _vm.$v.selectedEntite.$dirty,
              },staticStyle:{"padding-right":"0 !important"},attrs:{"label":"text","reduce":({ value }) => value,"options":_vm.entitesList,"placeholder":"Entité"},scopedSlots:_vm._u([{key:"no-options",fn:function(){return [_vm._v(" Liste vide ")]},proxy:true}]),model:{value:(_vm.selectedEntite),callback:function ($$v) {_vm.selectedEntite=$$v},expression:"selectedEntite"}}),(_vm.$v.selectedEntite.$dirty)?_c('span',{staticClass:"text-danger font-weight-normal",staticStyle:{"font-size":"x-small"}},[_vm._v(" "+_vm._s(!_vm.$v.selectedEntite.required ? "Champ obligatoire" : "")+" ")]):_vm._e()],1),_c('b-form-group',{attrs:{"label-cols":"4","label":"Organisation :","label-class":"font-weight-bold"}},[_c('v-select',{class:{
                'is-invalid form-control p-0':
                  _vm.$v.newActeur.Organisation.$error &&
                  _vm.$v.newActeur.Organisation.$dirty,
              },staticStyle:{"padding-right":"0 !important"},attrs:{"label":"text","reduce":(org) => org.value,"options":_vm.organisation,"placeholder":"Organisation"},scopedSlots:_vm._u([{key:"no-options",fn:function(){return [_vm._v(" Liste vide ")]},proxy:true}]),model:{value:(_vm.newActeur.Organisation),callback:function ($$v) {_vm.$set(_vm.newActeur, "Organisation", $$v)},expression:"newActeur.Organisation"}}),(_vm.$v.newActeur.Organisation.$dirty)?_c('span',{staticClass:"text-danger font-weight-normal",staticStyle:{"font-size":"x-small"}},[_vm._v(" "+_vm._s(!_vm.$v.newActeur.Organisation.required ? "Champ obligatoire" : "")+" ")]):_vm._e()],1),_c('b-form-group',{attrs:{"label-cols":"4","label":"Fonction :","label-class":"font-weight-bold"}},[_c('b-form-input',{class:{
                'is-invalid':
                  _vm.$v.newActeur.Fonction.$error &&
                  _vm.$v.newActeur.Fonction.$dirty,
              },staticStyle:{"padding-right":"0 !important"},attrs:{"placeholder":"Fonction","options":_vm.hierachie},model:{value:(_vm.newActeur.Fonction),callback:function ($$v) {_vm.$set(_vm.newActeur, "Fonction", $$v)},expression:"newActeur.Fonction"}}),(_vm.$v.newActeur.Fonction.$dirty)?_c('span',{staticClass:"text-danger font-weight-normal",staticStyle:{"font-size":"x-small"}},[_vm._v(" "+_vm._s(!_vm.$v.newActeur.Fonction.required ? "Champ obligatoire" : "")+" ")]):_vm._e()],1)],1),_c('div',{staticClass:"col-12 col-md-4 pr-0"},[_c('b-form-group',{attrs:{"label-cols":"4","label":"Prénom :","label-class":"font-weight-bold"}},[_c('b-form-input',{class:{
                'is-invalid':
                  _vm.$v.newActeur.Prenom.$error && _vm.$v.newActeur.Prenom.$dirty,
              },attrs:{"placeholder":"Prénom","type":"text"},model:{value:(_vm.newActeur.Prenom),callback:function ($$v) {_vm.$set(_vm.newActeur, "Prenom", $$v)},expression:"newActeur.Prenom"}}),(_vm.$v.newActeur.Prenom.$dirty)?_c('span',{staticClass:"text-danger font-weight-normal",staticStyle:{"font-size":"x-small"}},[_vm._v(" "+_vm._s(!_vm.$v.newActeur.Prenom.required ? "Champ obligatoire" : "")+" ")]):_vm._e()],1),_c('b-form-group',{attrs:{"label-cols":"4","label":"Type :","label-class":"font-weight-bold"}},[_c('v-select',{class:{
                'is-invalid form-control p-0':
                  _vm.$v.newActeur.type.$error && _vm.$v.newActeur.type.$dirty,
              },staticStyle:{"padding-right":"0 !important"},attrs:{"placeholder":"Type","options":['Externe', 'Interne']},scopedSlots:_vm._u([{key:"no-options",fn:function(){return [_vm._v(" Liste vide ")]},proxy:true}]),model:{value:(_vm.newActeur.type),callback:function ($$v) {_vm.$set(_vm.newActeur, "type", $$v)},expression:"newActeur.type"}}),(_vm.$v.newActeur.type.$dirty)?_c('span',{staticClass:"text-danger font-weight-normal",staticStyle:{"font-size":"x-small"}},[_vm._v(" "+_vm._s(!_vm.$v.newActeur.type.required ? "Champ obligatoire" : "")+" ")]):_vm._e()],1),_c('b-form-group',{attrs:{"label-cols":"4","label":"Niveau hiérarchique :","label-class":"font-weight-bold"}},[_c('v-select',{class:{
                'is-invalid form-control p-0':
                  _vm.$v.newActeur.Niveau.$error && _vm.$v.newActeur.Niveau.$dirty,
              },staticStyle:{"padding-right":"0 !important"},attrs:{"options":_vm.hierachie,"placeholder":"Niveau hiérarchique"},scopedSlots:_vm._u([{key:"no-options",fn:function(){return [_vm._v(" Liste vide ")]},proxy:true}]),model:{value:(_vm.newActeur.Niveau),callback:function ($$v) {_vm.$set(_vm.newActeur, "Niveau", $$v)},expression:"newActeur.Niveau"}}),(_vm.$v.newActeur.Niveau.$dirty)?_c('span',{staticClass:"text-danger font-weight-normal",staticStyle:{"font-size":"x-small"}},[_vm._v(" "+_vm._s(!_vm.$v.newActeur.Niveau.required ? "Champ obligatoire" : "")+" ")]):_vm._e()],1)],1),_c('div',{staticClass:"col-12 col-md-4"},[_c('b-form-group',{attrs:{"label-cols":"12","label":"Photo :","label-class":"font-weight-bold h-25"}},[_c('drop-zone',{attrs:{"image":'',"options":_vm.dropzoneOptions},on:{"change":_vm.change}})],1)],1)])]),_c('br'),_c('hr'),_c('div',{staticClass:"row mt-4"},[_c('div',{staticClass:"col-12 d-flex flex-wrap justify-content-between align-items-center"},[_c('span',{staticClass:"h4 text-secondary mr-auto my-auto"},[_vm._v("Liste des équipes ")]),_c('b-button',{staticStyle:{"background-color":"#007bffff !important","border-color":"#007bffff !important"},attrs:{"disabled":"","variant":"secondary"},on:{"click":_vm.addNewTeamInputs}},[_c('i',{staticClass:"mdi mdi-plus"}),_vm._v(" Affecter équipe")]),_c('div',{staticClass:"col-12 p-0"},[_c('hr',{staticClass:"mt-1 mb-3 bg-secondary"})])],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('b-table',{attrs:{"responsive":"","items":[],"fields":_vm.affectedTeams.tableFields,"show-empty":"","bordered":"","striped":""},scopedSlots:_vm._u([{key:"empty",fn:function(){return [_c('div',{staticClass:"text-center text-gray"},[_vm._v("Table vide")])]},proxy:true}])})],1)])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }